<template>
  <Animation3 />

  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <div id="teams" class="page">
    <div class="banner TeamBanner">
      <div class="container px-2">
        <div class="intro-text-wrapper">
          <h1 data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
            {{ content.Mainheading }}
          </h1>
          <p data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1500">
            {{ content.Description }}
          </p>
        </div>
      </div>
    </div>

    <div style="background: #f5f5f1;">
      <section class="teams container px-2" v-if="siteLanguage !== 'GR'">
        <!-- <span class="intro">{{ content.CTA.teamHeading }}</span> -->
        <h1 class="heading text-center">{{ content.CTA.teamsubHeading }}</h1>
        <!-- <p class="pDescription text-center">
          Everyone at Gap Dynamics is here for the same reason: we see it as our
          duty to improve the user experience of <br />
          the world by guiding clients through impactful digital transformations
        </p> -->
        <!-- <p class="teamDesc">{{ content.CTA.teamPara }}</p> -->
        <div class="card-container">
          {{ err }}
          <MemberCard
            v-for="sin in teams"
            :key="sin.id"
            :name="sin.name"
            :img="sin.img_card"
            :position="sin.designation"
            :slug="sin.slug"
            :BtnText="content.TeamViewLinkText"
          />
        </div>
      </section>
    </div>

    <section class="quote px-2">
      <div class="container" style="    padding-bottom: 100px;">
        <h2 class="text-center">{{ content.Mission.MainTitle }}</h2>
        <div>
          <div class="column">
            <p class="pDescription text-center">
              {{ content.Mission.Text }}
            </p>
          </div>
          <!-- <div class="column">
            <p>
              The whole is greater than the sum of its parts, but the team
              members at Fueled are pretty notable on their own. If you want to
              learn about a specific individual on our team, type their name
              below.
            </p>
            <Input name="NAME" type="text" required />
          </div> -->
        </div>
        <div class="polygon-main">
          <div class="polygon">
            <h3>{{ content.Mission.Mission1 }}</h3>
            <h1>01</h1>
          </div>
          <div class="polygon">
            <h3>
              {{ content.Mission.Mission2 }}
            </h3>
            <h1>02</h1>
          </div>
          <div class="polygon">
            <h3>{{ content.Mission.Mission3 }}</h3>
            <h1>03</h1>
          </div>
        </div>
      </div>

      <div class="padding-break-rl accent"></div>
    </section>

    <div style="background: #fff;" class=" ">
      <section class="teams container px-2">
        <div class="spacing">
          <h2 class="heading">{{ content.Clients.MainHeading }}</h2>
          <p class="pDescription">{{ content.Clients.Description }}</p>

          <div class="grid clients-block ">
            <div class="column">
              <img class=" " src="../assets/clients/bank.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/voda-fone.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/woelke.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/velti.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/1st_solution.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/comebis.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/pronovit.png" />
            </div>

            <div class="column">
              <img class=" " src="../assets/clients/the_team.png" />
            </div>
          </div>
        </div>
      </section>

      <div class="padding-break-rl accent1"></div>
    </div>
    <section class="join coreBg">
      <!-- <div class="padding-break-rl accent1"></div> -->
      <!-- <span class="block"></span> -->
      <div class="bg">
        <div class="container">
          <div class="CoreSec">
            <img src="../assets/images/shapes.png" />
            <h2 class="value-text">{{ content.Core.MainTitle }}</h2>
            <p class="pDescription">{{ content.Core.Description }}</p>

            <h2 class="title1">{{ content.Core.MainTitle1 }}</h2>
            <p class="pDescription">{{ content.Core.Text }}</p>
            <p class="pDescription">{{ content.Core.Text1 }}</p>
            <button>{{ content.Core.ButtonText }}</button>
          </div>
        </div>
      </div>
    </section>

    <section class="quote px-2 digital-transformation-machine">
      <div class="container" style="    padding-bottom: 100px;">
        <h2>{{ content.Qoute.MainTitle }}</h2>
        <div class="flex margin container">
          <div class="column">
            <blockquote>
              {{ content.Qoute.Text }}
            </blockquote>
            <blockquote>
              {{ content.Qoute.Text2 }}
            </blockquote>
          </div>
          <div class="column">
            <!-- <p>
              {{ content.Qoute.Text3 }}
            </p>-->
          </div>
        </div>
      </div>

      <div class="padding-break-rl accent1"></div>
    </section>

    <section class="join">
      <span class="block"></span>
      <div class="bg">
        <div class="container" style="margin-top: -104px;">
          <h2>{{ content.CTA.MainTitle }}</h2>
          <p class="pDescription">{{ content.CTA.Description }}</p>

          <div class="">
            <div class="techSlider">
              <Vue3Marquee>
                <div class="column">
                  <Nodejs class="tech-logo" />
                </div>
                <div class="column">
                  <Mongodb />
                </div>
                <div class="column">
                  <Python />
                </div>
                <div class="column">
                  <Mysql />
                </div>
              </Vue3Marquee>
            </div>

            <div class="techSlider">
              <Vue3Marquee direction="reverse">
                <div class="column">
                  <Csharp />
                </div>
                <div class="column">
                  <Django />
                </div>
                <div class="column">
                  <Express />
                </div>
                <div class="column">
                  <VueJs />
                </div>
              </Vue3Marquee>
            </div>
            <div class="techSlider">
              <Vue3Marquee>
                <div class="column">
                  <JavaScript />
                </div>

                <div class="column">
                  <Html />
                </div>

                <div class="column">
                  <Rreact />
                </div>

                <div class="column">
                  <Wordpress />
                </div>
              </Vue3Marquee>
            </div>
            <div class="techSlider">
              <Vue3Marquee direction="reverse">
                <div class="column">
                  <Gatsby />
                </div>

                <div class="column">
                  <Shopify />
                </div>

                <div class="column">
                  <Laravel />
                </div>

                <div class="column">
                  <Php />
                </div>
              </Vue3Marquee>
            </div>
            <!-- <p class="description"> -->
            <!-- {{ content.CTA.Description }} -->
            <!-- </p> -->

            <!-- <router-link
                :to="{
                  name: 'Jobs',
                }"
              >
                <WhiteButton
                  class=""
                  :name="content.CTA.ButtonText"
                  type=""
                  :disable="disable"
                />
              </router-link> -->

            <!-- <div class="column">
              <p>
                {{ content.CTA.OneLineDescription }}
              </p>

            </div>   -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import constant from "../config/constants"; //find all the api urls, assets, images path here

// import WhiteButton from "@/components/WhiteButton";
import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";
import MemberCard from "@/components/MemberCard";
import Animation3 from "@/components/Animation3";

import JavaScript from "../assets/technologies/javascript.svg";
import Laravel from "../assets/technologies/laravel.svg";
import Php from "../assets/technologies/php.svg";
import Python from "../assets/technologies/python.svg";

import Rreact from "../assets/technologies/react.svg";
import Gatsby from "../assets/technologies/gatsby.svg";
import Mysql from "../assets/technologies/mysql.svg";
import Mongodb from "../assets/technologies/mongodb.svg";
import Wordpress from "../assets/technologies/wordpress.svg";
import Html from "../assets/technologies/html.svg";
import Shopify from "../assets/technologies/shopify.svg";
import Nodejs from "../assets/technologies/nodejs.svg";

import Csharp from "../assets/technologies/csharp.svg";
import Django from "../assets/technologies/django.svg";
import Express from "../assets/technologies/express.svg";
import VueJs from "../assets/technologies/vue.svg";
import { Vue3Marquee } from "vue3-marquee";
import "vue3-marquee/dist/style.css";
import axios from "axios";
import "./style3.scss";

export default {
  components: {
    MemberCard,
    Vue3Marquee,
    Animation3,
    Python,
    Php,
    Laravel,
    JavaScript,
    Nodejs,
    Wordpress,
    Rreact,
    Gatsby,
    Mysql,
    Mongodb,
    Html,
    Shopify,
    Csharp,
    Django,
    Express,
    VueJs,

    // WhiteButton,
  },
  data: function() {
    return {
      teams: null,
      content: null,
      loading: null,
      err: "",
      siteLanguage: "",
      Seo: {},
    };
  },
  created() {
    this.siteLanguage = localStorage.getItem("siteLanguage");

    localStorage.getItem("siteLanguage") === "GR"
      ? ((this.content = LanguageStrings.gr.TeamPage),
        (this.Seo = SeoStrings.gr.Team))
      : ((this.content = LanguageStrings.en.TeamPage),
        (this.Seo = SeoStrings.en.Team));

    document.querySelector("head title").innerText = this.content.MetaTitle;

    axios
      .get(constant.API_URL + "team/member?siteLanguage=" + this.siteLanguage)
      .then((response) => {
        this.loading = true;
        if (response.data.data.length) {
          this.teams = response.data.data;
          /*
          this.teams = localStorage.getItem("siteLanguage") === "EN" ?
          response.data.data.filter((item)=> item.office.office_code=="PK" ) 
          : response.data.data.filter((item)=> item.office.office_code=="DE" );
          */
        } else {
          this.err = this.content.NoMemberFoundApiText;
        }
        this.loading = false;
      })
      .catch((err) => {
        this.err = err.message;
        this.loading = false;
      });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
