<template>
  <div class="member-card">
    <div class="header">
      <div class="avatar">
        <img :src="IMAGES_PATH+'team/'+img" :alt="name" />
        <div class="clip-border"></div>
      </div>
    </div>
     
    <span class="about">
       <router-link :to="{ name: 'TeamDetails', params: { slug: slug } }">
      <p class="name">{{ name }}</p>
      <p class="position">{{ position }}</p>

    
        <p class="contact">{{BtnText}} {{ name.split(' ')[0] }}</p>
      </router-link>
    </span>
    
  </div>
</template>

<script>

import constant from "../config/constants";    //find all the api urls, assets, images path here

export default {
  props: ["name", "img", "position", "slug", "BtnText"],

  data: function (){
    return{
       IMAGES_PATH: constant.IMAGES_URL,
    }
  
  }


};





</script>
